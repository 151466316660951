import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChatbotInitialiseMessage, ChatbotSettings, isChatbotInitialiseMessage } from './ChatbotTypes';
import { ChatBot } from './Chatbot';

const scriptUrlObj = new URL((document.currentScript as any)?.src);
const orationBaseUrl = `https://${scriptUrlObj.host}`;

function initialise(data: ChatbotInitialiseMessage) {
  const root = ReactDOM.createRoot(
    document.getElementById("chatbotroot") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <ChatBot baseUrl={orationBaseUrl} orgId={data.orgId} convId={data.convId} inboundAttributes={data.inboundAttributes} initialSettings={data.settings} initialResponse={data.initialResponse} />
    </React.StrictMode>
  );
}

window.addEventListener('message', ({ data }: { data: ChatbotInitialiseMessage }) => {
  if (isChatbotInitialiseMessage(data)) {
    console.log('Initialising chatbot', data);
    if (!data.orgId) {
      console.error("chatbotInitialise missing required parameter");
    } else {
      initialise(data);
    }
  }
});

window.addEventListener("load", () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orgId = urlParams.get("orgId");
  const convId = urlParams.get("convId") || undefined;
  const module = urlParams.get("module");
  const environment = urlParams.get("environment");
  const inboundAttributesStr = urlParams.get("inboundAttributes");
  const inboundAttributes = inboundAttributesStr ? JSON.parse(inboundAttributesStr) : { module, environment };
  console.log("Got query params", orgId, convId, inboundAttributes);
  if (orgId) {
    const initialResponse = urlParams.get("initialResponse") || undefined;
    const settingsStr = urlParams.get("settings");
    const settings = settingsStr ? JSON.parse(settingsStr) as ChatbotSettings : undefined;
    const initial: ChatbotInitialiseMessage = { message: "chatbotInitialise", orgId, convId, inboundAttributes, settings, initialResponse };
    initialise(initial);
  }
});

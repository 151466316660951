import { RequestTypeExt, RequestDetailsExt, ConversationResponseExt, ConversationRequestExt } from "@speech/conv-interface";

export async function makeRequest(baseUrl: string, orgId: string, requestType: RequestTypeExt, requestDetails: RequestDetailsExt, convId?: string): Promise<ConversationResponseExt> {
    const request: ConversationRequestExt = {
        requestType,
        requestDetails,
        channel: {
            type: "chatbot",
            device: "browser",
            integration: "TODO",
            platform: "Oration Chatbot",
            capabilities: ["text"],
            supportedResponses: ["ask", "play", "transfer", "end", "play"]
        },
        session: {
            id: convId
        }
    };
    const fetchRequest: RequestInit = {
        body: JSON.stringify(request),
        method: "POST",
        headers: {
            "X-Api-Key": `oration-stage-${orgId}`,
            "Content-Type": "application/json"
        }
    };
    console.log("Making conv eng request", request, fetchRequest);
    const fetchResponse = await fetch(`${baseUrl}/conv-engine/chatbot`, fetchRequest);
    // console.log("Fetch resp 1", fetchResponse)
    const json = await fetchResponse.json();
    console.log("Got conv eng response", json);
    return json;
}

export async function getSettings(baseUrl: string, orgId: string, module: string, environment: string, sessionId: string) {
    const fetchRequest: RequestInit = {
        method: "GET",
        headers: {
            "X-Api-Key": `oration-stage-${orgId}`,
            "Content-Type": "application/json"
        }
    };
    const fetchResponse = await fetch(`${baseUrl}/conv-engine/chatbot/settings?module=${encodeURIComponent(module)}&environment=${encodeURIComponent(environment)}&sessionId=${encodeURIComponent(sessionId)}`, fetchRequest);
    const json = await fetchResponse.json();
    console.log("Got settings response", json);
    return json;
}
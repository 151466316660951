import React from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import { Text, createEditor } from 'slate';
import { BlockPromptNode, ElementPromptNode, PromptNode, FeedbackPromptNode, TextPromptNode, isElementPromptNode } from './ChatbotTypes';
import ReactPlayer from 'react-player';
import Markdown from 'react-markdown';
import Carousel from './Carousel';

const ALL_MARKS = ["bold", "italic", "underline", "color"];

type OnChatLink = (resp: string) => void;
type OnPromptFeedback = (resp: string, actionId: string, question: string, feedbackReason?: string) => void;

export function PromptContent({ content, onChatLink, onPromptFeedback }: { content: BlockPromptNode[], onChatLink: OnChatLink, onPromptFeedback: OnPromptFeedback }) {
    const [initialValue] = React.useState(orationToSlate(content));

    const editor = React.useMemo(() => withReact(createEditor()), []);
    const renderElement = React.useCallback((props: any) => <Element {...props} onChatLink={onChatLink} onPromptFeedback={onPromptFeedback} />, [onChatLink]);
    const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, []);

    return <Slate editor={editor} initialValue={initialValue}>
        <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly
        />
    </Slate>;
}

const Element = ({ attributes, children, element, onChatLink, onPromptFeedback }:
    { attributes: any, children: any, element: ElementPromptNode, onChatLink: OnChatLink, onPromptFeedback: OnPromptFeedback }) => {
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'ul':
            return <ul className="chatbot-content-ul" style={style} {...attributes}>{children}</ul>;
        case 'h1':
            return <h1 className="chatbot-content-h1" style={style} {...attributes}>{children}</h1>;
        case 'h2':
            return <h2 className="chatbot-content-h2" style={style} {...attributes}>{children}</h2>;
        case 'h3':
            return <h3 className="chatbot-content-h3" style={style} {...attributes}>{children}</h3>;
        case 'li':
            return <li className="chatbot-content-li" style={style} {...attributes}>{children}</li>;
        case 'ol':
            return <ol className="chatbot-content-ol" style={style} {...attributes}>{children}</ol>;
        case 'clnk':
            return Array.isArray(element.links)
                ? <ChatbotLinks links={element.links} as={element.as} layout={element.layout} onChatLink={onChatLink} />
                : <span />;
        case 'mgrimg':
            return typeof element.src === "string" && element.src
                ? <img src={element.src} alt={element.name} className="chatbot-content-img" />
                : null;
        case 'youtube':
            return typeof element.src === "string" && element.src
                ? <ReactPlayer url={element.src} width={295} height={240} />
                : null;
        case 'link':
            return typeof element.url === "string" && element.url
                ? <a href={element.url} className="chatbot-content-link" rel="noopener noreferrer" target={element.target === "_top" ? "_top" : "_blank"}>{element.txt || element.url}</a>
                : null;
        case 'html':
            return typeof element.content === "string" && element.content
                ? <span className="chatbot-content-html" dangerouslySetInnerHTML={{ __html: element.content }} />
                : null;
        case 'p':
            return <div className="chatbot-content-paragraph" style={style} {...attributes}>{children}</div>;
        case 'feedback':
            return <Feedback element={element} onPromptFeedback={onPromptFeedback} />;
        case 'multi':
            if (element.source === "content") {
                return <PromptContent content={element.content || []} onChatLink={onChatLink} onPromptFeedback={onPromptFeedback} />;
            } else if (element.source === "attr") {
                return <span>{element.attr || ""}</span>;
            } else {
                return <p></p>;
            }
        case 'markdown':
            return <Markdown>{element.markdownContent}</Markdown>;
        case 'carousel':
            if (element.source === "content") {
                return <Carousel auto={true} autoSlideInterval={5000} carouselContent={element.content}>
                    {element.content?.map((item, index) => {
                        return (<div className='chatbot-carousel-prompt-box'>
                            <PromptContent key={index} content={item} onChatLink={onChatLink} onPromptFeedback={onPromptFeedback} />
                        </div>
                        );
                    })}
                </Carousel>;
            } else {
                return <p>{element.attr}</p>;
            }
        default:
            return null;
    }
};

const Feedback = ({ element, onPromptFeedback }: { element: FeedbackPromptNode, onPromptFeedback: OnPromptFeedback }) => {
    const [thumb, setThumb] = React.useState(0);
    const [askReason, setAskReason] = React.useState(0);
    const [reason, setReason] = React.useState("");

    const thumbUp = async () => {
        setThumb(1);
        // post like
        onPromptFeedback("Like", element.actionId, element.question);
    };
    const thumbDown = () => {
        setThumb(2);
        setAskReason(1);
        // post dislike
    };
    const onKeyDown = (e: { key: string; }) => {
        if (e.key == "Enter") {
            onPromptFeedback("Dislike", element.actionId, element.question, reason);
            setReason("");
        }
    };

    return (
        <div>
            <div className="thumbs-container">
                <span className="thumb" style={{ color: thumb == 1 ? 'initial' : 'transparent', textShadow: '0 0 0 grey', cursor: 'pointer' }} onClick={thumbUp}>
                    &#128077;
                </span>
                <span className="thumb" style={{ color: thumb == 2 ? 'initial' : 'transparent', textShadow: '0 0 0 grey', cursor: 'pointer' }} onClick={thumbDown}>
                    &#x1F44E;
                </span>
            </div>
            <div>
                <input
                    placeholder="Please tell as why you don't like it'"
                    style={{ display: askReason == 1 ? 'block' : 'none', width: "100&" }}
                    value={reason}
                    onChange={(e) => { setReason(e.target.value); }}
                    onKeyDown={onKeyDown}></input>
            </div>
        </div>

    );
};

export const Leaf = ({ attributes, children, leaf }: { attributes: any, children: any, leaf: TextPromptNode }) => {
    if (leaf.bold) {
        children = <strong className="chatbot-content-bold">{children}</strong>;
    }
    if (leaf.itl) {
        children = <em className="chatbot-content-italic">{children}</em>;
    }
    if (leaf.udl) {
        children = <u className="chatbot-content-underline">{children}</u>;
    }
    return <span {...attributes} style={leaf.color ? { color: leaf.color } : {}}>{children}</span>;
};

type ChatbotLink = { resp: string, text?: string };
type ChatbotLinksParams = { links: ChatbotLink[], as: "lnks" | "btns", layout: "vert" | "horl", onChatLink: OnChatLink }

function ChatbotLinks({ links = [], as = "btns", layout = "vert", onChatLink }: ChatbotLinksParams) {
    const handleResponse = (resp: ChatbotLink) => {
        if (onChatLink) {
            onChatLink(resp.resp);
        }
    };

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: ChatbotLink) => {
        e.preventDefault();
        handleResponse(link);
    };

    return as === "lnks"
        ? <div className={`chatbot-content-chatlinks-${layout}`}>
            {links.filter((link) => link).map((link) => <a href="#" key={link.resp} className={`chatbot-content-chatlink-${layout}`} onClick={(e) => handleLinkClick(e, link)}>{link.text || link.resp}</a>)}
        </div>
        : <div className={`chatbot-content-chatbuttons-${layout}`}>
            {links.filter((link) => link).map((link) => <button key={link.resp} className={`chatbot-content-chatbutton-${layout}`} onClick={() => handleResponse(link)}>{link.text || link.resp}</button>)}
        </div>;

}

function orationToSlate(nodes: PromptNode[], isChild?: boolean): PromptNode[] {
    const validNodes = Array.isArray(nodes) ? nodes : [];
    if (!isChild && !validNodes.some((node) => !Text.isText(node) && !isElementPromptNode(node))) {
        // Oration phone prompts do not have a top level block node. Add one for slate    
        return [{ type: "p", children: orationToSlate(validNodes, true) }];
    }

    // Oration removes the empty child elements which slate requires, so put them back
    return validNodes.map((node) => {
        if (Text.isText(node)) {
            return node;
        } else if (!Array.isArray(node.children) || node.children.length === 0) {
            // In Slate the marks are on the text not the parent
            const text = { text: "" };
            ALL_MARKS.filter((mark) => (node as any)[mark]).forEach((mark) => (text as any)[mark] = (node as any)[mark]);
            ALL_MARKS.forEach((mark) => delete (node as any)[mark]);
            return { ...node, children: [text] };
        } else {
            return { ...node, children: orationToSlate(node.children, true) };
        }
    });
}
import { useState, useEffect } from "react";
import { ArrowLeft, ArrowRight } from "react-feather";

const Carousel: React.FC<{
  children?: React.ReactNode;
  auto: boolean;
  autoSlideInterval: number;
  carouselContent: any;
}> = ({
  children,
  auto = true,
  autoSlideInterval = 10000,
  carouselContent
}) => {
    const [curr, setCurr] = useState(0);
    const [carouselSize, setCarouselSize] = useState(0);
    const [autoSlide, setAutoSlide] = useState(auto);

    const prev = () =>
      setCurr((curr) => (curr === 0 ? carouselSize - 1 : curr - 1));
    const next = () => {
      setCurr((curr) => (curr === carouselSize - 1 ? 0 : curr + 1));
    };

    useEffect(() => {
      setCarouselSize(carouselContent.length);
      if (!autoSlide) return;
      const slideInterval = setInterval(next, autoSlideInterval);
      return () => clearInterval(slideInterval);
    }, [carouselContent, carouselSize, autoSlide]);
    return (
      <div
        className="chatbot-carousel-frame"
        onMouseEnter={() => {
          setAutoSlide(false);
        }}
        onMouseLeave={() => {
          setAutoSlide(true);
        }}
      >
        <div
          className="chatbot-carousel-frame-inner"
          style={{ transform: `translateX(-${curr * 100}%)` }}
        >
          {children}
        </div>
        <button
          onClick={prev}
          className="chatbot-carousel-button-left"
        >
          <ArrowLeft size={15} />
        </button>
        <button
          onClick={next}
          className="chatbot-carousel-button-right"
        >
          <ArrowRight size={15} />
        </button>

        <div className="chatbot-carousel-slider-frame">
          <div className="chatbot-carousel-slider-inner">
            {carouselContent.map((_: any, i: number) => (
              <div
                className={`
              chatbot-carousel-slider
              ${curr === i ? "p-2" : "opacity-50"}
            `}
                onClick={() => setCurr(i)}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

export default Carousel;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { makeRequest } from './Api';
import {
  ConversationResponseExt, PromptDetails, RequestDetails, RequestDetailsExt, RequestType, RequestTypeExt, ResponseDetails, ResponseType, ResumedResponseDetails, SessionEndedRequestDetails,
  StartSessionRequestDetails, TransferResponseDetails, UserInputRequestDetails
} from '@speech/conv-interface';
import { StringStringDict } from '@speech/conv-interface';
import { ChatbotBubbleContent, ChatbotInboundMessage, ChatbotOutboundMessage, ChatbotSettings, isChatbotUpdateMessage } from './ChatbotTypes';
import { PromptContent } from './ChatbotPromptContent';
import Cookies from 'js-cookie';
import { v4 } from 'uuid';
import MobileDetect from 'mobile-detect';
type ChatBotProps = { baseUrl: string, orgId: string, convId?: string, inboundAttributes: StringStringDict, initialSettings?: ChatbotSettings, initialResponse?: string };

export function ChatBot({ baseUrl, orgId, convId: inboundConvId, inboundAttributes, initialSettings, initialResponse }: ChatBotProps) {
  const [bubbles, setBubbles] = React.useState([] as ChatbotBubbleContent[]);
  const [userResponse, setUserResponse] = React.useState("");
  const [convId, setConvId] = React.useState<string | undefined>("");
  const [callEnded, setCallEnded] = React.useState(false);
  const [transferred, setTransferred] = React.useState(false);
  const [makingRequest, setMakingRequest] = React.useState(false);
  const [settings, setSettings] = React.useState<ChatbotSettings>(typeof initialSettings === "object" ? initialSettings : {});
  const [sessionTimeout, setSessionTimeout] = React.useState<NodeJS.Timeout | undefined>();
  const [loaded, setLoaded] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState<boolean>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [webSocket, setWebSocket] = React.useState<WebSocket>();
  const [isAgent, setIsAgent] = React.useState<boolean>(false);
  const [messengerDeployment, setMessengerDeployment] = React.useState<string | null>();

  const bottomRef = React.useRef();
  const inputRef = React.useRef();

  const onWebsocketMessage = React.useCallback((event: any) => {
    if (webSocket !== undefined) {
      const eventData = JSON.parse(event.data);
      if (eventData.class === "SessionResponse" && eventData.code === 200) {
        let chatHistory = "";
        bubbles.forEach((bubble) => {
          chatHistory = chatHistory + bubble.type + ": " + bubble.text + "\n";
        });
        const botChatHistory = {
          action: "onMessage",
          token: convId,
          message: {
            type: "Text",
            text: chatHistory
          }
        };
        webSocket!.send(JSON.stringify(botChatHistory));
        // Start agent session
        setIsAgent(true);
      } else if (eventData.body.direction === "Outbound" && eventData.body.events) {
        if (eventData.body.events[0].eventType === "Typing" && bubbles.findIndex(x => x.type === "agentTyping") === -1) {
          setBubbles([...bubbles, { type: "agentTyping", text: "Agent is typing..." }]);
        }
      } else if (eventData.body.direction === "Outbound" && eventData.body.type === "Text") {
        if (eventData.body.content) {
          const listOfContent: any[] = [];
          eventData.body.content.forEach((e: { attachment: { url: any; }; }) => {
            listOfContent.push({ type: "mgrimg", src: e.attachment.url, name: "agentImg" });
          });
          setBubbles([...bubbles.filter(x => x.type !== "agentTyping"), { type: "bot", text: eventData.body.text, content: listOfContent }]);
        } else if (eventData.body.text.includes("youtube") || eventData.body.text.includes("youtu.be")) {
          setBubbles([...bubbles.filter(x => x.type !== "agentTyping"), { type: "bot", text: eventData.body.text, content: [{ type: "youtube", src: eventData.body.text, name: "youtubelink" }] }]);
        } else {
          setBubbles([...bubbles.filter(x => x.type !== "agentTyping"), { type: "bot", text: eventData.body.text }]);
        }
      }
      setMakingRequest(false);
    }
  }, [webSocket, bubbles]);

  React.useEffect(() => {
    if (webSocket !== undefined) {
      webSocket.addEventListener("message", onWebsocketMessage);
    }
    return () => webSocket?.removeEventListener("message", onWebsocketMessage);
  }, [webSocket, onWebsocketMessage]);


  React.useEffect(() => {
    if (messengerDeployment !== undefined) {
      setWebSocket(new WebSocket("wss://webmessaging.mypurecloud.com.au/v1?deploymentId=" + messengerDeployment));
    }
  }, [messengerDeployment]);

  React.useEffect(() => {
    if (webSocket !== undefined) {
      webSocket.onopen = (() => {
        const sessionConfig = {
          action: "configureSession",
          deploymentId: messengerDeployment,
          token: convId
        };

        setMakingRequest(true);
        webSocket.send(JSON.stringify(sessionConfig));
      });
    }

  }, [webSocket]);

  React.useEffect(() => {
    if (initialSettings?.overrideAll) {
      setLoaded(true);
    }
    // Send the session start when the bot starts    
    handleSendStartRequest();
  }, []);

  React.useEffect(() => {
    // Focus the input box after any render (but not on mobile as that brings up the keyboard)
    if (inputRef && inputRef.current && !settings.internal && !isMobile) {
      (inputRef.current as any).focus();
    }
  });

  React.useEffect(() => {
    // Scroll to the last chat bubble whenever one is added
    (bottomRef.current as any)?.scrollIntoView({ behavior: "smooth" });
  }, [bubbles]);

  React.useEffect(() => {
    // Stop the timer on unload
    return () => {
      if (sessionTimeout) {
        clearTimeout(sessionTimeout);
      }
    };
  }, [sessionTimeout]);

  React.useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent, -1);
    setIsMobile(md.mobile() ? true : false);
  }, []);

  const onMessage = React.useCallback(({ data }: { data: ChatbotInboundMessage }) => {
    if (isChatbotUpdateMessage(data) && data.settings) {
      if (data.settings.overrideAll) {
        setSettings({ ...data.settings });
      } else {
        setSettings({ ...settings, ...data.settings });
      }
    }
  }, [settings]);

  React.useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  const handleSendMessage = (message: ChatbotOutboundMessage) => {
    try {
      // If we are in an iFrame, window.parent is the parent window. If not window.parent is the current window
      (window.top || window).postMessage(message, "*");
    } catch (err) {
      console.log("Could not send message to client");
    }
  };

  // React.useEffect(() => {
  //   // Ignore this if initial load where showing is undefined
  //   if (typeof showing === "boolean") {
  //     const message: ChatOutboundMessage = { message: showing ? "chatbotShowing" : "chatbotHidden" };
  //     try {
  //       console.log("Sending message to client", message);
  //       // If we are in an iFrame, window.parent is the parent window. If not window.parent is the current window
  //       window.parent.postMessage(message, "*");
  //     } catch (err) {
  //       console.log("Could not send message to client", message, err);
  //     }
  //   }
  // }, [showing]);

  // React.useEffect(() => {
  //   // Set the styling
  //   if (settings?.css) {
  //     console.log("Adding style", settings.css);
  //     document.getElementById("chatbot-style")?.remove();
  //     const style = document.createElement('style');
  //     style.id = "chatbot-style";
  //     document.head.appendChild(style);
  //     style.appendChild(document.createTextNode(settings.css));
  //   }
  // }, [settings?.css]);

  const handleSendRequest = async (requestType: RequestTypeExt, requestDetails: RequestDetailsExt, bubbles: ChatbotBubbleContent[], requestConvId: string | undefined) => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
    setBubbles([...bubbles, { type: "botwait", text: "..." }]);
    setCallEnded(false);
    setMakingRequest(true);
    let resp = await makeRequest(baseUrl, orgId, requestType, requestDetails, requestConvId);
    setConvId(resp.session.id);
    bubbles.push(...getBubblesFromResponse(resp));
    setBubbles(bubbles);
    const transferDetails = resp.responseDetails as TransferResponseDetails;
    if (transferDetails.to) {
      if (transferDetails.to.startsWith("messengerDeployment")) {
        setMessengerDeployment(transferDetails.to.split("=")[1]);
      }
    }


    if (!settings.overrideAll) {
      if (resp.responseDetails.parameters?.chatbotSettings) {
        setSettings({ ...JSON.parse(resp.responseDetails.parameters?.chatbotSettings), ...settings });
        setLoaded(true);
      }
    }

    // Play any prompts it wanted us to play
    for (let i = 0; resp.responseType === "play" && i < 100; i++) {
      setBubbles([...bubbles, { type: "botwait", text: "..." }]);
      resp = await makeRequest(baseUrl, orgId, "promptsComplete", {}, resp.session.id);
      bubbles.push(...getBubblesFromResponse(resp));
    }

    // Send the initial response if needed
    if (requestType === "sessionStart" && resp.responseType === "ask" && typeof initialResponse === "string") {
      const requestDetails = {
        mode: "text",
        results: [
          {
            text: initialResponse,
            confidence: 1,
            intent: "TODO",
            slots: {}
          }
        ]
      } as UserInputRequestDetails;
      bubbles.push({ type: "user", text: initialResponse });
      setBubbles([...bubbles, { type: "botwait", text: "..." }]);
      resp = await makeRequest(baseUrl, orgId, "userInput", requestDetails, resp.session.id);
      bubbles.push(...getBubblesFromResponse(resp));
    }

    setBubbles(bubbles);
    setMakingRequest(false);
    if (resp.responseType === "end") {
      setCallEnded(true);
      setSessionTimeout(undefined);
      Cookies.remove(`chatbot_session_${orgId}`);
    }
    else if (resp.responseType === "transfer") {
      setTransferred(true);
      setSessionTimeout(undefined);
      Cookies.remove(`chatbot_session_${orgId}`);
      // Send end request to conv engine
      const sessionEndedRequest = {
        message: "Transferred",
        reason: "Transferred"
      } as SessionEndedRequestDetails;
      await makeRequest(baseUrl, orgId, "sessionEnded", sessionEndedRequest, resp.session.id);



      // Redirect browser
      if (transferDetails.to) {
        if (!transferDetails.to.startsWith("messengerDeployment")) {
          if (!settings.internal) {
            const transferDetails = resp.responseDetails as TransferResponseDetails;
            (window.top || window).location.href = transferDetails.to;
          }
        }
      }
    } else {
      // Put this function here so it uses the latest value of bubbles
      const handleSessionTimeout = () => {
        setBubbles([...bubbles, { type: "bot", text: "Sorry, your session has timed out" }]);
        setCallEnded(true);
        setConvId(undefined);
        Cookies.remove(`chatbot_session_${orgId}`);
      };
      const idleSessionExpiry = (settings.idleSessionExpiryHours || 8) * 60 * 60 * 1000;
      if (settings.useSessionCookie) {
        Cookies.set(`chatbot_session_${orgId}`, resp.session.id, { expires: new Date(Date.now() + idleSessionExpiry) });
      }
      const timeout = setTimeout(handleSessionTimeout, idleSessionExpiry);
      setSessionTimeout(timeout);
    }
    if (requestType === "sessionStart" || requestType === "sessionResume") {
      handleSendMessage({ message: "chatbotShowing" });
    }
    return resp;
  };

  const handleSendStartRequest = () => {
    const initialConvId = inboundConvId || Cookies.get(`chatbot_session_${orgId}`);
    const deviceId = Cookies.get(`chatbot_device_${orgId}`) || v4();
    if (settings.useDeviceIdCookie) {
      const deviceIdExpiry = (settings.deviceIdExpiryDays || 365) * 24 * 60 * 60 * 1000;
      Cookies.set(`chatbot_device_${orgId}`, deviceId, { expires: new Date(Date.now() + deviceIdExpiry) });
    }
    const requestDetails = {
      "cti": {
        cli: deviceId,
        ...inboundAttributes
      }
    } as StartSessionRequestDetails;
    if (inboundConvId) {
      requestDetails.cti.useConvAttrs = true;
    }
    handleSendRequest(!inboundConvId && initialConvId ? "sessionResume" : "sessionStart", requestDetails, [], initialConvId);
  };

  const handleSendUserInputRequest = async (text: string) => {
    if (convId && !callEnded) {
      const requestDetails = {
        mode: "text",
        results: [
          {
            text,
            confidence: 1,
            intent: "N/A",
            slots: {}
          }
        ]
      } as UserInputRequestDetails;
      setUserResponse("");
      handleSendRequest("userInput", requestDetails, [...bubbles, { type: "user", text }], convId);
    }
    if (isAgent && webSocket !== undefined) {
      const msg = {
        action: "onMessage",
        token: convId,
        message: {
          type: "Text",
          text: text
        }
      };
      setMakingRequest(true);
      webSocket!.send(JSON.stringify(msg));
      setBubbles([...bubbles, { type: "user", text: text }]);
      setUserResponse("");
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && userResponse.trim()) {
      handleSendUserInputRequest(userResponse.trim());
    }
  };

  const handleInputChange = (event: any) => {
    setUserResponse(event.target.value);
    const msg = {
      action: "onMessage",
      token: convId,
      message: {
        type: "Event",
        events: [
          {
            eventType: "Typing",
            typing: {
              type: "On"
            }
          }
        ]
      }
    };
    if (isAgent && webSocket !== undefined) {
      webSocket.send(JSON.stringify(msg));
    }

  };

  const handlePromptFeedback = async (vote: string, actionId: string, question: string, feedbackReason?: string) => {
    const resp = await makeRequest(baseUrl, orgId, "promptFeedback" as RequestTypeExt, { feedback: vote, actionId: actionId, question: question, feedbackReason: feedbackReason }, convId);
    console.log(resp);
  };

  const getBubbleContent = (seg: ChatbotBubbleContent) => {
    switch (seg.type) {
      case "bot": return seg.content ? <div>
        <PromptContent content={seg.content} onChatLink={handleSendUserInputRequest} onPromptFeedback={handlePromptFeedback} />
      </div> : <div><>{seg.text}</></div>;
      case "user": return <>{seg.text}</>;
      case "agentTyping": return <>{seg.text}</>;
      case "botwait": return <div className="chatbot-agent-loader" />;
    }
  };

  const disabled = makingRequest || (callEnded && !transferred);
  const placeholder = makingRequest ? "Loading..." : ((callEnded && !transferred) ? "Session ended" : "Type your message...");
  return loaded
    ? <>
      {settings.css && <style>{settings.css}</style>}
      <div className="chatbot-main">
        <div className="chatbot-header">
          {settings.image && <img className='chatbot-icon' src={settings.image} />}
          {settings.title && <div className='chatbot-title'>{settings.title}</div>}
          {settings.hideButtonEnabled && <div className='chatbot-hide' onClick={() => handleSendMessage({ message: "chatbotHidden" })}>Hide</div>}
        </div>
        <div className='chatbot-window'>
          {bubbles.map((seg, index) =>
            <div key={`seg-${index}`} className={seg.type === "user" ? "chatbot-user-bubble" : "chatbot-agent-bubble"}>
              <div className={seg.type === "user" ? "chatbot-user-content" : "chatbot-agent-content"}>{getBubbleContent(seg)}</div>
            </div>
          )}
          {(callEnded && !transferred) &&
            <div className='chatbot-startagain' onClick={handleSendStartRequest}>Start Again</div>
          }
          <div ref={bottomRef as any} />
        </div>
        <div className='chatbot-inputs'>
          <input className='chatbot-input' placeholder={placeholder} ref={inputRef as any} disabled={disabled} value={userResponse} onKeyDown={handleKeyDown} onChange={handleInputChange} />
          <button className='chatbot-sendbutton' disabled={disabled || !userResponse.trim()} onClick={() => handleSendUserInputRequest(userResponse)}>Send</button>
        </div>
      </div >
    </>
    : null;
}

const getBubblesFromResponse = (response: ConversationResponseExt): ChatbotBubbleContent[] => {
  if (response.responseType === "resumed") {
    const resumedResponse = response.responseDetails as ResumedResponseDetails;
    const resp: ChatbotBubbleContent[] = [];
    resumedResponse.previous.forEach((prev) => resp.push(...getBubblesFromRequestOrResponse(prev.direction, prev.type, prev.details)));
    return resp;
  } else {
    return getBubblesFromRequestOrResponse("response", response.responseType, response.responseDetails);
  }
};

const getBubblesFromRequestOrResponse = (direction: "request" | "response", type: RequestType | ResponseType, details: RequestDetails | ResponseDetails): ChatbotBubbleContent[] => {
  if (direction === "request") {
    if (type === "userInput") {
      const userInputRequest = details as UserInputRequestDetails;
      return [{ type: "user", text: userInputRequest.results[0].text }];
    } else {
      return [];
    }
  } else {
    const prompts = (details as any).prompts?.initial as PromptDetails[];
    if (prompts) {
      return prompts.map((prompt) => ({ type: "bot", text: prompt.text, content: prompt.content || [] } as ChatbotBubbleContent));
    } else {
      return [];
    }
  }
};

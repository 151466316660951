import { StringStringDict } from "@speech/conv-interface";

export interface ChatbotSettings {
    css?: string;
    title?: string;
    image?: string;
    hideButtonEnabled?: boolean;
    overrideAll?: boolean;
    internal?: boolean;
    idleSessionExpiryHours?: number;
    useSessionCookie?: boolean;
    useDeviceIdCookie?: boolean;
    deviceIdExpiryDays?: number;
}

export interface ChatbotInitialiseMessage extends ChatbotInboundMessage {
    message: "chatbotInitialise";
    orgId: string;
    convId?: string;
    inboundAttributes: StringStringDict;
    settings?: ChatbotSettings;
    initialResponse?: string;
}

export function isChatbotInitialiseMessage(message: ChatbotInboundMessage): message is ChatbotInitialiseMessage {
    return typeof (message) === "object" && message.message === "chatbotInitialise";
}

export interface ChatbotUpdateMessage extends ChatbotInboundMessage {
    message: "chatbotUpdate";
    settings: ChatbotSettings;
}

export function isChatbotUpdateMessage(message: ChatbotInboundMessage): message is ChatbotUpdateMessage {
    return typeof (message) === "object" && message.message === "chatbotUpdate";
}

export interface ChatbotInboundMessage {
    message: "chatbotInitialise" | "chatbotUpdate";
}

export interface ChatbotOutboundMessage {
    message: "chatbotShowing" | "chatbotHidden";
}

export interface ChatbotBubbleContent {
    type: "bot" | "user" | "botwait" | "agentTyping";
    text: string;
    content?: any[];
}

export interface TextPromptNode {
    text: string;
    bold?: boolean;
    itl?: boolean;
    udl?: boolean;
    color?: string;
}

export interface ElementPromptNodeBase {
    children: PromptNode[];
    align?: "left" | "center" | "right" | "justify";
}

export interface ImagePromptNode extends ElementPromptNodeBase {
    type: "mgrimg";
    src: string;
    name: string;
}

export interface YoutubePromptNode extends ElementPromptNodeBase {
    type: "youtube";
    src: string;
    name: string;
}

export interface ChatbotLinkPromptNode extends ElementPromptNodeBase {
    type: "clnk";
    links: { resp: string, txt?: string }[];
    as: "btns" | "lnks";
    layout: "vert" | "horl";
}

export interface HtmlPromptNode extends ElementPromptNodeBase {
    type: "html";
    content: string;
}

export interface FeedbackPromptNode extends ElementPromptNodeBase {
    type: "feedback";
    actionId: string;
    question: string;
}

export interface MultiPromptNode extends ElementPromptNodeBase {
    type: "multi";
    mode: string;
    source: string;
    content?: BlockPromptNode[];
    attr?: string;
    attributeType?: string;
}

export interface LinkPromptNode extends ElementPromptNodeBase {
    type: "link";
    url: string;
    txt?: string;
    target: "_top" | "_blank";
}

export interface MarkdownPromptNode extends ElementPromptNodeBase {
    type: "markdown";
    markdownContent: string;
}

export interface CarouselPromptNode extends ElementPromptNodeBase {
    type: "carousel";
    mode: string;
    source: string;
    content?: BlockPromptNode[][];
    attr?: string;
}


export interface BlockPromptNode extends ElementPromptNodeBase {
    type: "p" | "ol" | "ul" | "li" | "h1" | "h2" | "h3";
}

export type ElementPromptNode = ImagePromptNode | YoutubePromptNode | ChatbotLinkPromptNode | HtmlPromptNode | LinkPromptNode | BlockPromptNode | FeedbackPromptNode | MultiPromptNode | MarkdownPromptNode | CarouselPromptNode;

export function isElementPromptNode(node: PromptNode): node is ElementPromptNode {
    switch ((node as any).type) {
        case "mgrimg":
        case "youtube":
        case "clnk":
        case "html":
        case "link":
        case "feedback":
        case "multi":
        case "markdown":
            return true;
        default:
            return false;
    }
}

export type PromptNode = TextPromptNode | ElementPromptNode;